<template>
  <div class="container-fluid">
    <div class="row">
      <sidebar></sidebar>
      <nav-header></nav-header>

      <!-- MAIN CONTENT start -->
      <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10" >
        <breadcrumb :items="pageData.breadcrumb"></breadcrumb>

        <section class="px-sm-0 mx-sm-0 px-lg-5 mx-lg-5  mt-0 mt-md-5">
          <div class="container-fluid ">
            <div class="row justify-content-around mb-5 mt-md-5 mt-lg-0 pt-5 pt-sm-0">
              <!--
                  CARDS
               -->
              <!--<div class="col-sm-12 mb-0 mb-sm-4">
                <div class="card-primary h-100">
                  <div class="card-body d-flex align-items-center">
                    <h1 style="color: #fff">{{ $t('registration.registration-starts-at') }}</h1>
                  </div>
                </div>
              </div>-->
<!--              <div class="col-sm-6 mb-4 mb-sm-0 ">-->
<!--                <div class="card-primary h-100">-->
<!--                  <div class="card-body d-flex align-items-center">-->
<!--                    <p>{{ $t('registration.header-buttons.first-button') }}</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
              <div class="col-sm-12">
                <div class="card-primary">
                  <div class="card-body">
                    <p>
                      {{ $t('registration.header-buttons.preReg-button') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5 mb-5 ">
              <div class="col text-center mt-5">
                <h1 v-if="guideLink" class="mb-4">
                  <a target="_blank" :href="guideLink">{{ $t('registration.message-to-students') }}</a>
                </h1>
              </div>
            </div>

            <!--
                Unique and same values!!!
                --btn-close
                    href="#mcmodule_0" , data-target="#cmodule_0", aria-controls="cmodule_0"

                -- btn-registration
                    data-target="#cmodule_0", aria-controls="cmodule_0"

                --module-body
                    id="cmodule_0"
             -->

            <!-- modules -->

            <h3 class="text-center" v-if="canChooseMinorProgram()">
                  <minor-programs-modal v-if="!user.must_choose_english" @change="minorProgramChanged($event)"></minor-programs-modal>
                </h3>
            <p @click="handleClickPreRegSubjects" class="text-center" style="font-size:20px; cursor:pointer; font-weight:bold;">  {{$t('registration.preRegSubject')}}</p>
            <div class="row justify-content-start pt-5 pt-sm-0" id="modules">

              <sk-cards v-if="programModules.loading"
                        width-class="col-6"
                        :height="0.2"
                        :count="4"></sk-cards>
              <div v-else class="col-md-12 col-lg-6 mb-5 px-0 px-sm-4" v-for="(programModule, i) of programModules.data">
                <div :class="['module', { active: activeCollapses[i] }]">
                  <div class="module-header">
                    <!-- x button visible only on mobile -->
                    <div class="module-close text-right d-none mb-4 mt-2">
                      <a class="btn-close"  v-b-toggle="'cmodule_' + i" role="button">
                        <img src="/static/images/icons/registrations/X.png" alt="" class="img-fluid">
                      </a>
                    </div>
                    <button v-b-toggle="'cmodule_' + i" class="btn btn-registration" type="button">
                      {{ getProgramModuleName(programModule) }}
                      <span class="toggle-icon"></span>
                    </button>
                  </div>
                  <b-collapse v-model="activeCollapses[i]" @shown="loadSubjects(i)" :id="'cmodule_' + i" class="module-body">
                    <div class="registration-table">
                      <table class="table" v-if="programModule.subjects">
                        <thead>
                        <tr>
                          <th>{{ $t('registration.subject') }}</th>
                          <th>{{ $t('registration.evaluation') }}</th>
                          <th>{{ $t('registration.ects') }}</th>
                          <th>{{ $t('registration.semester') }}</th>
                          <th><span>&nbsp;</span></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <table v-for="(subjectBlock, blockName) of programModule.subjects">
                            <tr class="mt-2 mb-1" v-if="blockName !== '-'">
                              <td class="bg-red text-white text-center">
                                {{ blockName }}
                              </td>
                            </tr>
                            <tr v-for="(subject, s) of subjectBlock" @click="onSubjectClick(subject)">
                              <td>{{ subject.code }} {{ helpers.getLocalizedField(subject, 'subject') }}</td>
                              <td class="text-center">
                                <span v-if="subject.Eval">{{ subject.Eval }}</span>
                              </td>
                              <td class="text-center">
                                {{ subject.ECTS }}
                              </td>
                              <td class="text-center">
                                {{ helpers.getSubjectSemester(subject.subject_semester) }}
                              </td>
                              <td class="text-center">
                                <img v-if="isAlreadyRegistered(subject.subjectID)" src="/static/images/icons/registrations/close.png" class="img-fluid">
                                <img v-else src="/static/images/icons/registrations/open.png" class="img-fluid">
                              </td>
                            </tr>
                          </table>
                        </tr>

                        </tbody>
                      </table>
                    </div>
                  </b-collapse>
                  <!--                  </div>-->
                </div>
              </div>
              <!-- Single module end -->

              <!-- Single module start -->
              <div v-if="isVisibleFreeCreditSubjects" class="col-md-12 col-lg-6 mb-5 px-0 px-sm-4">
                <div :class="['module', { active: isActiveFreeCreditSubjects }]">
                  <div class="module-header">
                    <!-- x button visible only on mobile -->
                    <div class="module-close text-right d-none mb-4 mt-2">
                      <a class="btn-close"  v-b-toggle="'free-credit'" role="button">
                        <img src="/static/images/icons/registrations/X.png" alt="" class="img-fluid">
                      </a>
                    </div>
                    <button v-b-toggle="'free-credit'" class="btn btn-registration" type="button">
                      {{ $t('registration.free-credit-subjects') }}
                      <span class="toggle-icon"></span>
                    </button>
                  </div>
                  <b-collapse id="free-credit" class="module-body">
                    <div class="registration-table">
                      <table class="table">
                        <thead>
                        <tr>
                          <th>{{ $t('registration.subject') }}</th>
                          <th>{{ $t('registration.evaluation') }}</th>
                          <th>{{ $t('registration.ects') }}</th>
                          <th><span>&nbsp;</span></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(subject, s) of freeCreditSubjects" @click="onSubjectClick(subject)">
                          <td>{{ subject.code }} {{ helpers.getLocalizedField(subject, 'subject') }}</td>
                          <td class="text-center">
                            <span v-if="subject.Eval">{{ subject.Eval }}</span>
                          </td>
                          <td class="text-center">
                            {{ subject.ECTS }}
                          </td>
                          <td class="text-center">
                            <img v-if="isAlreadyRegistered(subject.subjectID)" src="/static/images/icons/registrations/close.png" class="img-fluid">
                            <img v-else src="/static/images/icons/registrations/open.png" class="img-fluid">
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-collapse>
                  <!--                  </div>-->
                </div>
              </div>
              <!-- Single module end -->

              <!-- Single module start -->
              <div v-if="user && user.minorProgramID !== null && minorSubjects.length > 0" class="col-md-12 col-lg-6 mb-5 px-0 px-sm-4">
                <div :class="['module', { active: isActiveMinorSubjects }]">
                  <div class="module-header">
                    <!-- x button visible only on mobile -->
                    <div class="module-close text-right d-none mb-4 mt-2">
                      <a class="btn-close"  v-b-toggle="'minor-subjects'" role="button">
                        <img src="/static/images/icons/registrations/X.png" alt="" class="img-fluid">
                      </a>
                    </div>
                    <button v-b-toggle="'minor-subjects'" class="btn btn-registration" type="button">
                      {{ $t('registration.minor-subjects') }}
                      <span class="toggle-icon"></span>
                    </button>
                  </div>
                  <b-collapse id="minor-subjects" class="module-body">
                    <div class="registration-table">
                      <table class="table">
                        <thead>
                        <tr>
                          <th>{{ $t('registration.subject') }}</th>
                          <th>{{ $t('registration.evaluation') }}</th>
                          <th>{{ $t('registration.ects') }}</th>
                          <th>{{ $t('registration.semester') }}</th>
                          <th><span>&nbsp;</span></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(subject, s) of minorSubjects" @click="onSubjectClick(subject)">
                          <td> {{ helpers.getLocalizedField(subject, 'subject') }}</td>
                          <td class="text-center">
                            <span v-if="subject.Eval">{{ subject.Eval }}</span>
                          </td>
                          <td class="text-center">
                            {{ subject.ECTS }}
                          </td>
                          <td class="text-center">
                            {{ helpers.getSubjectSemester(subject.subject_semester) }}
                          </td>
                          <td class="text-center">
                            <img v-if="isAlreadyRegistered(subject.subjectID)" src="/static/images/icons/registrations/close.png" class="img-fluid">
                            <img v-else src="/static/images/icons/registrations/open.png" class="img-fluid">
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-collapse>
                  <!--                  </div>-->
                </div>
              </div>
              <!-- Single module end -->

            </div>
            <!-- modules end-->
            <pre-reg-subjects-show></pre-reg-subjects-show>
          </div>
        </section>

      </main>
      <!-- MAIN CONTETN end -->
    </div>
  </div>
</template>

<script>
  import sidebar from '../components/navbar/sidebar';
  import sidebarSm from '../components/navbar/sidebar-sm';
  import appHeader from '../components/app-header';
  import pageHeader from '../components/page/page-header';
  import { mapGetters } from 'vuex';
  import NavHeader from '../components/navbar/nav-header';
  import Breadcrumb from '../components/page/breadcrumb';
  import PageTitle from '../components/page/page-title';
  import SkList from '../components/skeletons/sk-list';
  import SkCards from '../components/skeletons/sk-cards';
  import breadcrumb from '../components/page/breadcrumb';
  import PreRegSubjectsShow from '../components/registration/PreRegSubjectsShow';
  import MinorProgramsModal from '../components/pre-registration/minor-program-modal';
  export default {
    name: 'registration',
    components: {
      SkCards,
      SkList,
      PageTitle,
      Breadcrumb,
      NavHeader,
      sidebar,
      sidebarSm,
      appHeader,
      pageHeader,
      breadcrumb,
      PreRegSubjectsShow,
      MinorProgramsModal
    },

    data() {
      return {
        freeCreditSubjects: [],
        maxFreeCreditAmount: 0,
        takenFreeCreditAmount: 0,
        minorSubjects: [],
        chosenSubject: null,
        activeCollapses: [],
        isActiveFreeCreditSubjects: false,
        isActiveMinorSubjects: false,
        guideLink: '',
        isOpen: false,
        alreadyRegistered: [],
      }
    },

    computed: {
      isVisibleFreeCreditSubjects() {
        return this.freeCreditSubjects.length > 0;
      },

      pageData() {
        return {
          title: this.$t('registration.title'),
          breadcrumb: [{
            icon: '',
            title: this.$t('home.title'),
            link: '/',
            isActive: false,
          },
            {
              icon: '',
              title: this.$t('registration.title'),
              link: '/pre-registration',
              isActive: true,
            }],
        };
      },
      ...mapGetters({
        user: 'auth/user',
        programModules: 'pre_registration/programModules',
        mustChooseSubjects: 'auth/mustChooseSubjects'
      })
    },

    watch: {
      user() {
        if(this.user.minorProgramID !== null) {
          this.loadMinorSubjects();
        }

        this.loadRegistrationGuide();
      },
    },

    mounted() {
      if (navigator.appVersion.indexOf("Chrome/") != -1) {
        this.root = document.documentElement;
        this.root.style.setProperty('--scroll', 'scroll');
        this.root.style.setProperty('--auto', 'auto');
      }

      this.loadModules();
      this.loadAlreadyRegistered();



 


    },

    methods: {

      canChooseMinorProgram() {

            

        const subjects = []
        for (const s in this.minorSubjects) {
            
          
          
            subjects.push(s)
          
        }
 

      if(subjects.filter(o => o.Eval !== null).length > 0) return false;

        return this.user.ects <= 180 && this.user.studyLevel === 1;
      },




      handleClickPreRegSubjects(){

        this.$bvModal.show('pre-reg-modal-show');



},
      loadMinorSubjects() {
        this.$store.dispatch('pre_registration/loadMinorSubjects')
          .then(response => {
            this.minorSubjects = response.data.data;
          })
      },

      isAlreadyRegistered(subjectId) {
        return this.alreadyRegistered.map(o => o.subjectID).indexOf(subjectId) > -1;
      },

      handleModalClose() {
        this.chosenSubject = null;
      },

      loadAlreadyRegistered() {
        this.$store.dispatch('pre_registration/loadAlreadyRegistered')
          .then(response => {
              this.alreadyRegistered = response.data.data;
              const ects = this.alreadyRegistered.map(o => o.ECTS).reduce((a, b) => parseInt(a) + parseInt(b))
              if (ects >= 30 && this.mustChooseSubjects) {
                window.location.reload()
              }

              // if (ects < 30 && !this.mustChooseSubjects && (this.user && !this.user.is_on_last_course)) {
              //   window.location.reload()
              // }
          });
      },

      loadModules() {
        this.$store.dispatch('pre_registration/loadProgramModules').then(() => {
          this.isOpen = true;

          this.$store.dispatch('pre_registration/loadFreeCreditSubjects')
            .then(response => {
              this.freeCreditSubjects = response.data.data.subjects;
              this.maxFreeCreditAmount = parseInt(response.data.data.max_credit_amount);
              this.takenFreeCreditAmount = parseInt(response.data.data.taken_credit_amount);
            });

          if(this.user && this.user.minorProgramID !== null) this.loadMinorSubjects();
        });
      },

      getProgramModuleName(programModule) {
        if (programModule.concentrationID) {
          return this.helpers.getLocalizedField(programModule, 'program_module') + ' - ' + this.helpers.getLocalizedField(programModule, 'concentration')
        }
        return this.helpers.getLocalizedField(programModule, 'program_module')
      },

      minorProgramChanged(programID) {
        if(!programID) {
          this.minorSubjects = [];
        } else {
          this.$store.dispatch('pre_registration/loadMinorSubjects')
          .then(response => {
            this.minorSubjects = response.data.data;
          })
        }
      },

      onSubjectClick(subject) {
        if(this.canRemoveTaken(subject)) {
          this.removeTaken(subject);
        } else if(!this.canRemoveTaken(subject) && this.canTake(subject) && this.isAlreadyTaken(subject)) {
          this.alreadyTaken(subject);
        } else {
          this.take(subject);
        }
      },

      isAlreadyTaken(subject) {
        return this.canTake(subject) && subject.Eval !== null && subject.Eval !== 'F';
      },

      canRemoveTaken(subject) {
        return this.isAlreadyRegistered(subject.subjectID);
      },

      canTake(subject) {
        return !this.isAlreadyRegistered(subject.subjectID);
      },

      alreadyTaken(subject) {
        this.$swal({
          title: "",
          text: this.$t('registration.already-taken-warning'),
          type: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t('registration.minor-modal.ok'),
          cancelButtonText: this.$t('registration.minor-modal.cancel'),
          closeOnConfirm: false,
          closeOnCancel: false
        })
          .then((result) => {
            if (result && result.isConfirmed) {
              this.take(subject);
            }
          });
      },

      take(subject) {
        this.$swal({
          title: "",
          text: this.$t('registration.confirm-pre-registration'),
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: this.$t('registration.minor-modal.ok'),
          cancelButtonText: this.$t('registration.minor-modal.cancel'),
          closeOnConfirm: false,
          closeOnCancel: false
        }).then((result) => {
          if(result && result.isConfirmed) {
            this.chosenSubject = subject;
            this.choose(subject);
          }
        });
      },

      choose(subject) {
        this.$store.dispatch('pre_registration/register', {
          subjectId: subject.subjectID
        })
          .then(response => {
            
            this.helpers.notifySuccessMessage(response.data.message);
            this.loadAlreadyRegistered();
          }).catch(error => {
          this.helpers.notifyErrorMessage(error);
        });
      },

      removeTaken(subject) {
        this.$swal({
          title: "",
          text: this.$t('registration.remove-taken-warning'),
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: this.$t('registration.minor-modal.ok'),
          cancelButtonText: this.$t('registration.minor-modal.cancel'),
          closeOnConfirm: false,
          closeOnCancel: false
        })
          .then((result) => {
            if (result && result.isConfirmed) {
              this.removeTakenSubject(subject);
            }
          });
      },

      removeTakenSubject(subject) {
        this.$store.dispatch('pre_registration/removeSubject', subject.subjectID)
          .then((response) => {
            this.helpers.notifySuccessMessage(this.$t('registration.messages.successfully_removed'));
            this.loadAlreadyRegistered();
          })
      },

      loadSubjects(programModuleIdx) {
        this.$store.dispatch('pre_registration/loadProgramModuleSubjects', programModuleIdx)
      },

      loadRegistrationGuide() {
        this.$store.dispatch('pre_registration/loadRegistrationGuide', this.user.programID)
        .then(response => {
          this.guideLink = response.data.data;
        })
      }
    }
  };
</script>

<style scoped>

</style>
