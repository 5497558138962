<template>


<div>
    <b-modal  
    :no-close-on-esc="true"
     :no-close-on-backdrop="true"
      @shown="onShown()"
      :hide-header="true"
      :hide-footer="true"
      content-class="modal-blue"
      id="pre-reg-modal-show"
      size="xl"
      scrollable
      centered>
        <!-- Modal content-->
        <div class="row ">
          <div class="col mb-5 ">
            <h4 class="mb-4">{{$t('registration.preRegSubject')}}</h4>
            
                   
            <p v-for="(subject,i) of preRegSubjects" style="cursor:pointer"  > {{subject.subject_code}} | {{  helpers.getLocalizedField(subject, 'subject')}} </p>


          </div>
        </div>
        <div class="row">
          <div class="col mt-0 pt-3 mt-sm-5 pt-sm-0 mb-3">
            <button type="button" class="btn btn-light-bold" @click="hideModal()">{{ $t('registration.close') }}</button>
          </div>
        </div>
    </b-modal>
     
  </div>





    </template>

    <script>
    export default{

        data(){

            return{

                preRegSubjects:[]

            }

        },

        
        methods:{

            hideModal() {
        this.$bvModal.hide('pre-reg-modal-show');
        this.$emit('closed'); 
        
        
      },
      onShown(){

        
      this.$store.dispatch('pre_registration/getPreRegisteredSubjectsToShow').then(response=>{

        
                  this.preRegSubjects=response.data

}) 

     



      },
     


        }



    }



</script>